import React, { useState } from 'react';
import {
  Collapse,
  Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
  Form, FormGroup, Label, Input, Button,
} from 'reactstrap';

const TopBar = (props) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    let user = props.user;
    const logout = props.logout;

    const { setRoute } = props;

    const toggleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    }

    return (
        <Navbar  id="myHeader" expand="md" fixed="top">
            <NavbarBrand href="/"><img src="/logo.png" style={{width: '140px'}} /></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar}>{user && <span style={{fontSize: '14px', color: '#fff'}}>{user.name}</span>} 
                <img src="/menu_icon.png" style={{ width: '40px' }} alt="icon" /></NavbarToggler>
            <Collapse isOpen={navbarOpen} navbar>
                <Nav className="ml-auto" navbar>
                {
                    !user && <>
                        <NavItem className="myNavItem " onClick={() => {
                            setRoute('/login');
                            setNavbarOpen(false);
                        }} >Login</NavItem>
                        <NavItem className="myNavItem " onClick={() => {
                            setRoute('/register');
                            setNavbarOpen(false);
                        }}>Register</NavItem>
                    </>
                }
                {
                    user && <>
                        <NavItem className="myNavItem" onClick={() => {
                            setRoute('/myprofile');
                            setNavbarOpen(false);
                        }}>Profile</NavItem>
                        <NavItem className="myNavItem" onClick={() => {
                            logout();
                            setNavbarOpen(false);
                        }}>Log Out</NavItem>
                    </>
                }
                </Nav>
            </Collapse>
        </Navbar>
    );
}

export default TopBar;