import React, { useState, useMemo, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TopBar from './TopBar';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import './App.css';
import countryList from 'react-select-country-list';
import ReactPlayer from "react-player";
import countriesAll from 'countries-list/dist/countries.json';
import continentsAll from 'countries-list/dist/continents.json';
import axios from 'axios';
import settings from './settings';
import FileService from './FileService';
import ProfileEditor from './ProfileEditor';

const App = () => {

  const [route, setRoute] = useState('/');
  const [registerModel, setRegisterModel] = useState({type: 'player'});
  const [loginModel, setLoginModel] = useState({type: 'player'});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [registering, setRegistering] = useState(false);
  const [user, setUser] = useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const api = axios.create({
    baseUrl: settings.apiUrl
  });
  const fileService = new FileService();
  const continents = Object.keys(continentsAll).reduce((cnts, k) => {
    cnts.push({key: k, name: continentsAll[k]});
    return cnts;
  }, []);
  const countries = Object.keys(countriesAll).reduce((cous, c) => {
    cous.push({...countriesAll[c], key: c})
    return cous;
  }, []);

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem('goalOnUser'));
    setUser(u);
  }, []);

  const submitRegister = async (e) => {
    e.preventDefault();
    setRegistering(true);
    let availableEmail = (await axios.post(settings.apiUrl + 'auth/checkemail', {email: registerModel.email})).data;
    if(!availableEmail.isAvailable){
      setErrorMessage('This email is already in use. Try resetting your password if you do not remember it.');
      setTimeout(() => {
        setErrorMessage('');
        setRegistering(false);
      }, 5000);
      return false;
    }
    if(registerModel.password !== registerModel.confirmPassword){
      setErrorMessage('The passwords you entered do not match.');
      setTimeout(() => {
        setErrorMessage('');
        setRegistering(false);
      }, 5000);
    }
    axios.post(settings.apiUrl + 'auth/register', registerModel).then(r => {
      setSuccessMessage('You have successfully been registered. You can now login and purchase a subscription.');
      setTimeout(() => {
        setSuccessMessage("");
        setRegisterModel({type: 'player'});

        setRegistering(false);
        setRoute('/login');
      })
    }).then(e => {
      setErrorMessage(e.data);

      setRegistering(false);
    });
  }

  const login = (e) => {
    e.preventDefault()
    if(!loginModel.email || !loginModel.password){
      setErrorMessage('You have to complete your email and password to login');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000)
      return false;
    }
    axios.post(settings.apiUrl + 'auth/login', loginModel).then(r => {
      localStorage.setItem('goalOnUser', JSON.stringify(r.data));
      setUser(r.data);
      setRoute('/myprofile');
    }).catch(e => setErrorMessage(e.data));
  };
  
  const logout = () => {
    localStorage.setItem('goalOnUser', null);
    setUser(null);
    setRoute('/');
  };

  const refreshAccount = () => {
    axios.post(settings.apiUrl + 'auth/refreshdata', user).then(r => {
      setUser(r.data);
      localStorage.setItem('goalOnUser', JSON.stringify(r.data));
    }).catch(e => setErrorMessage(e.data));
  }

  useEffect(() => {
    if(route === '/myprofile'){
      window.paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function(data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: 'P-3HG89959GY111441GMGPHZBY'
        });
        },
        onApprove: function(data, actions) {
        createPlayer(user.userId, data.subscriptionId, 'basic');
        }
      }).render('#paypal-button-container-P-3HG89959GY111441GMGPHZBY');
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            /* Creates the subscription */
            plan_id: 'P-6RR547651K4648415MGPH4GY'
          });
        },
        onApprove: function(data, actions) {
          createPlayer(user.userId, data.subscriptionId, 'advanced');
        }
      }).render('#paypal-button-container-P-6RR547651K4648415MGPH4GY');
    }
  }, [route]);

  const createPlayer = (userId, subscriptionId, type) => {
    axios.post(settings.apiUrl + 'payments/verify', {userId, subscriptionId, type}).then(r => {
      refreshAccount();
    });
  }

  return <>
    <TopBar setRoute={setRoute} user={user} logout={logout} />
    {
      route === '/' && <Container style={{paddingTop: '120px'}}>
        <h3>Welcome to Goal On</h3>
        <ReactPlayer url={'/video.mp4'} width="100%" height="50%" controls={true} />
        <p>Goal on is the easiest and fastest way for football players to create an online profile.</p>
        <p>For a low yearly fee you can create an account, upload your data and be searchable by 
          teams and managers.
        </p>
        <p>
          You just Register, pay a yearly subscription and complete your profile, and then Clubs and Managers from around
          the world can find you if they search for players like you.
        </p>
        <p>
          <img src="/positions.jpg" style={{width: '100%'}} alt="positions"/>
        </p>
        <p>
          You can add atributes like the Continent and the country you live in, the positions that you play, as well as
          some soft skills.
        </p>
        <ReactPlayer url={'/video2.mp4'} width="100%" height="50%" controls={true} />
        {
          !user && <Row>
                      <Col xs="12">
                        <br />
                        <h4 className="text-center">Are you ready to come aboard?</h4>
                        <br />
                      </Col>
                      <Col xs="6" className="text-center">
                        <Button color="info" onClick={() => setRoute('/login')}>Login</Button>
                      </Col>
                      <Col xs="6" className="text-center">
                        <Button color="primary" onClick={() => setRoute('/register')}>Register</Button>
                      </Col>
                    </Row>
        }
        {
          user && <Row>
            <Col md="12">
              <hr />
              <Button color="primary" onClick={() => setRoute('/myprofile')} style={{width: '100%'}}>My Profile</Button>
            </Col>
          </Row>
        }
        <br /><br /><br />
      </Container>
    }
    {
      route === '/login' && <Container style={{paddingTop: '120px'}}>
        <Form onSubmit={login} style={{paddingTop: '10opx'}}>
          <FormGroup>
            <Label>Email</Label>
            <Input type="email" onChange={e => setLoginModel({...loginModel, email: e.target.value})} />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input type="password" onChange={e => setLoginModel({...loginModel, password: e.target.value})} />
          </FormGroup>
          <Button style={{width: '100%'}} color="primary" size="sm">Login</Button>
        </Form>
      </Container>
    }
    {
      route === '/register' && <Container style={{paddingTop: '120px'}}>
        <Form onSubmit={submitRegister}>
          <FormGroup>
            <Label>Select Continent</Label>
            <Input type="select" onChange={(e) => setRegisterModel({...registerModel, continent: e.target.value})}>
              <option value="">Select Continent</option>
              {
                continents.map(c => <option key={c.key} value={c.key}>{c.name}</option>)
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select Country</Label>
            <Input type="select" onChange={(e) => setRegisterModel({...registerModel, country: e.target.value})}>
              <option value="">Select Country</option>
              {
                countries.filter(c => registerModel.continent && c.continent === registerModel.continent)
                .map(c => <option key={c.key} value={c.key}>{c.name}</option>)
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input type="text" onChange={e => setRegisterModel({...registerModel, name: e.target.value})} /> 
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input type="email" onChange={e => {
              setRegisterModel({...registerModel, email: e.target.value});
            }} /> 
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input type="password" onChange={e => setRegisterModel({...registerModel, password: e.target.value})} />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input type="password" onChange={e => setRegisterModel({...registerModel, confirmPassword: e.target.value})} />
          </FormGroup>
          {
            errorMessage && <Alert color="danger">{errorMessage}</Alert>
          }
          {
            successMessage && <Alert color="success">{successMessage}</Alert>
          }
          <Row>
            <Col xs="12" className="text-center">
              <Button color="primary" disabled={registering} size="sm">Register</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    }
    {
      route === '/myprofile' && <Container style={{paddingTop: '120px'}}>
        {
          !user.active && <>
            <h3>Welcome {user.name}</h3>
            <p>You have successfully created an account at Goal On.</p>
            <h3 className="text-center">Purchase a subscription</h3>
            <Row>
              <Col md="6">
                <h2 className="text-center">Basic Subscription</h2>
                <ul style={{listStyle: 'none', textAlign: 'center'}} className="featureList">
                  <li>Price: <del className="text-danger">50&euro;</del> 25&euro; / Year until 31/12/2021</li>
                  <li>Create Player Profile</li>
                  <li>Add Bio</li>
                  <li>Self-assessment</li>
                  <li>Select up to 2 positions you play in</li>
                  <li>Upload up to 20 Photos</li>
                  <li>Upload up to 10 Videos from Youtube</li>
                  <li>Be discoverable by Footbal Clubs and Agents</li>
                </ul>
                <div id="paypal-button-container-P-3HG89959GY111441GMGPHZBY"></div>
              </Col>
              <Col md="6">
                <h2 className="text-center">Advanced Subscription</h2>
                <ul style={{listStyle: 'none', textAlign: 'center'}}  className="featureList">
                  <li>Price: <del className="text-danger">100&euro;</del> 50&euro; / Year until 31/12/2021</li>
                  <li>Create Player Profile</li>
                  <li>Add Bio</li>
                  <li>Self-assessment</li>
                  <li>Select up to 4 positions you play in</li>
                  <li>Upload unlimited Photos</li>
                  <li>Upload unlimited Videos from Youtube</li>
                  <li>Be discoverable by Footbal Clubs and Agents</li>
                  <li>Be prioritized in the searches of Football Clubs and Agents</li>
                </ul>
                <div id="paypal-button-container-P-6RR547651K4648415MGPH4GY"></div>
              </Col>
            </Row>
            <p>
              If you have already purchased a subscription, you can click 
              <Button color="warning" size="sm" onClick={refreshAccount}>Refresh</Button> to refresh your payment status
            </p>
            <p>To complete your registration and be able to create your Football Profile, you have to 
              subscribe to our Service with a Basic or an Advanced account.  
            </p>
            <p>
              After that, you will be able to upload Photos and Videos, select positions you play in and 
              when available, apply to challenges Teams or Scouters provide and build up your fame.
            </p>
            <p>
              If you select the Basic profile, you can upload up to 20 photos, 10 videos from youtube and select
              up to 2 positions you play in.
            </p>
            <p>
              If you select the Advanced account, you can upload unlimmited photos and videos, select up to 
              4 positions you play in and you will appear higher in the searches of the Teams and Managers.
            </p>
            
            
          </>
        }
        {
          user.active && user.player && <ProfileEditor user={user} setUser={setUser} />
        }
      </Container>
    }
  </>;
}

export default App;
