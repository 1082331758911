import React, { useEffect, useParams, useState } from 'react';
import settings from './settings';
import FileService from './FileService';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert,
    Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import countriesAll from 'countries-list/dist/countries.json';
import continentsAll from 'countries-list/dist/continents.json';
import axios from 'axios';

const ProfileEditor = (props) => {

    const [showPosition1Modal, setShowPosition1Modal] = useState(false);
    const [showPosition2Modal, setShowPosition2Modal] = useState(false);
    const [showPosition3Modal, setShowPosition3Modal] = useState(false);
    const [showPosition4Modal, setShowPosition4Modal] = useState(false);
    const [addingImageModal, setAddingImageModal] = useState(false);
    const [addingVideoModal, setAddingVideoModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [newYoutubeLink, setNewYoutubeLink] = useState('');
    const [newImage, setNewImage] = useState({});
    const { user, setUser } = props;
    const continents = Object.keys(continentsAll).reduce((cnts, k) => {
        cnts.push({key: k, name: continentsAll[k]});
        return cnts;
    }, []);
    const countries = Object.keys(countriesAll).reduce((cous, c) => {
        cous.push({...countriesAll[c], key: c})
        return cous;
    }, []);
    const fileService = new FileService();

    const togglePosition1Modal = () => {
        setShowPosition1Modal(!showPosition1Modal);
    }
    const togglePosition2Modal = () => {
        setShowPosition2Modal(!showPosition2Modal);
    }
    const togglePosition3Modal = () => {
        setShowPosition3Modal(!showPosition3Modal);
    }
    const togglePosition4Modal = () => {
        setShowPosition4Modal(!showPosition4Modal);
    }
    const toggleAddingImageModal = () => {
        setAddingImageModal(!addingImageModal);
    }
    const toggleAddingVideoModal = () => {
        setAddingVideoModal(!addingVideoModal);
    }

    const setPosition = (posNum, pos) => {
        if(posNum === 1){
            setUser({
                ...user, 
                player: {
                    ...user.player,
                    position1: pos
                }
            });
            setShowPosition1Modal(false);
        }
        if(posNum === 2){
            setUser({
                ...user, 
                player: {
                    ...user.player,
                    position2: pos
                }
            });
            setShowPosition2Modal(false);
        }
        if(posNum === 3){
            setUser({
                ...user, 
                player: {
                    ...user.player,
                    position3: pos
                }
            });
            setShowPosition3Modal(false);
        }
        if(posNum === 4){
            setUser({
                ...user, 
                player: {
                    ...user.player,
                    position4: pos
                }
            });
            setShowPosition4Modal(false);
        }
    }

    const editProfilePic = () => {
        document.getElementById('picImage').click();
    }

    const uploadProfilePic = (file) => {
        fileService.uploadFile(file).then((r) => {
            setUser({
                ...user,
                player: {
                    ...user.player,
                    profilePic: r
                }
            });
        });
    }

    const uploadImage = (file) => {
        fileService.uploadFile(file).then(r => {
            let img = JSON.parse(r)[0];
            setNewImage(img);
        })
    };
    const commentOnNewImage = (c) => {
        setNewImage({
            ...newImage,
            description: c
        });
    };

    const appendToImages = () => {
        let images = user.player.images ? JSON.parse(user.player.images) : [];
        if(images.length >= 20 && user.player.subscriptionType === 'basic'){
            setErrorMessage('You can upload up to 20 Photos with the basic profile.');
            return 0;
        }
        images.push(newImage);
        setUser({
            ...user,
            player: {
                ...user.player,
                images: JSON.stringify(images)
            }
        });
        setNewImage({});
        setAddingImageModal(false);
    }

    const appendVideo = () => {
        let videos = user.player.videos ? JSON.parse(user.player.videos) : [];
        if(videos.length >= 10 && user.player.subscriptionType === 'basic'){
            setErrorMessage('You can upload up to 10 Videos with the basic profile.');
            return 0;
        }
        videos.push(newYoutubeLink.replace("\'", ''));
        console.log(videos);
        setUser({
            ...user,
            player: {
                ...user.player,
                videos: JSON.stringify(videos)
            }
        });
        setAddingVideoModal(false);
        console.log(user.player.videos);
    }

    const savePlayer = () => {
        axios.post(settings.apiUrl + 'auth/saveplayer', user.player).then(r => {
            setSuccessMessage("Your profile has been saved successfully");
            refreshAccount();
        }).catch(e => setErrorMessage(e.data));
    };

    const refreshAccount = () => {
        axios.post(settings.apiUrl + 'auth/refreshdata', user).then(r => {
            setUser(r.data);
            localStorage.setItem('goalOnUser', JSON.stringify(r.data));
        }).catch(e => setErrorMessage(e.data));
      };

    return <>
        <Row>
            <Col xs="4" onClick={editProfilePic}>
            <img src={!user.player.profilePic ? `/userPlaceholder.jpeg` : fileService.getUrl(JSON.parse(user.player.profilePic)[0].name)} 
                style={{width: '100%'}}/>
            <Input type="file" style={{display: 'none'}} id="picImage"
            onChange={(e) => uploadProfilePic(e.target.files[0])}/>
            </Col>
            <Col xs="8" className="text-end">
                {user.name} <br /><br />
                <span className={user.player.subscriptionType === 'Basic' ? 'bg-warning' : 'bg-success'} style={{padding: '5px', borderRadius: '5px'}}>
                    {user.player.subscriptionType ? user.player.subscriptionType.toUpperCase() : ''}
                </span>
            </Col>
        </Row>
        <hr />
        <h4>Position 1</h4>
        <Button onClick={togglePosition1Modal} color="primary" size="sm" style={{width: '100%'}}>
            {
            user.player.position1 ? user.player.position1 : 'Select first position' 
            }
        </Button>
        <h4>Position 2</h4>
        <Button onClick={togglePosition2Modal} color="primary" size="sm" style={{width: '100%'}}>
            {
            user.player.position2 ? user.player.position2 : 'Select second position' 
            }
        </Button>
        {
            user.player.subscriptionType === 'advanced' && <>
            <h4>Position 3</h4>
            <Button onClick={togglePosition3Modal} color="primary" size="sm" style={{width: '100%'}}>
                {
                user.player.position3 ? user.player.position3 : 'Select third position' 
                }
            </Button>
            <h4>Position 4</h4>
            <Button onClick={togglePosition4Modal} color="primary" size="sm" style={{width: '100%'}}>
                {
                user.player.position4 ? user.player.position4 : 'Select fourth position' 
                }
            </Button>
            </>
        }
        <hr />
        <h4>Self Assessment</h4>
        <p>The more accurate your self assessment is, the more probable it is to get 
            found by Football Clubs and Managers.
        </p>
        <FormGroup>
            <FormGroup>
                <Label>Ball Skills: {user.player.ballSkills || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.ballSkills || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            ballSkills: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
            <FormGroup>
                <Label>Passing: {user.player.passing || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.passing || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            passing: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
            <FormGroup>
                <Label>Shooting: {user.player.shooting || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.shooting || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            shooting: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
            <FormGroup>
                <Label>Defence: {user.player.defence || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.defence || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            defence: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
            <FormGroup>
                <Label>Physical: {user.player.physical || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.physical || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            physical: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
            <FormGroup>
                <Label>Mental: {user.player.mental || 1}</Label>
                <Input type="range" min={1} max={10} defaultValue={user.player.mental || 1 } 
                    onChange={(e) => setUser({
                        ...user, 
                        player: {
                            ...user.player,
                            mental: e.target.valueAsNumber
                        }
                    })}/>
            </FormGroup>
        </FormGroup>
        <hr />
        <FormGroup>
            <Label>Bio</Label>
            <Input type="textarea" value={user.player.bio} 
            onChange={(e) => setUser({
                ...user, 
                player: {
                    ...user.player,
                    bio: e.target.value
                }
            })} />
        </FormGroup>
        <FormGroup>
            <Label>Continent</Label>
            <Input type="select" defaultValue={user.player.continent} 
            onChange={(e) => setUser({...user, player:{...user.player, continent: e.target.value}})}>
              <option value="">Select Continent</option>
              {
                continents.map(c => <option key={c.key} value={c.key}>{c.name}</option>)
              }
            </Input>
        </FormGroup>
        <FormGroup>
            <Label>Select Country</Label>
            <Input type="select" defaultValue={user.player.country} 
            onChange={(e) => setUser({...user, player: {...user.player, country: e.target.value}})}>
              <option value="">Select Country</option>
              {
                countries.filter(c => user.player.continent && c.continent === user.player.continent)
                .map(c => <option key={c.key} value={c.key}>{c.name}</option>)
              }
            </Input>
        </FormGroup>
        <FormGroup>
            <Label>Passport</Label>
            <Input type="select" defaultValue={user.player.passport} 
            onChange={(e) => setUser({...user, player: {...user.player, passport: e.target.value}})}>
              <option value="">Select Country</option>
              {
                countries.map(c => <option key={c.key} value={c.key}>{c.name}</option>)
              }
            </Input>
        </FormGroup>
        <Row>
            <Col xs="6">
                <FormGroup>
                    <Input type="checkbox" defaultChecked={user.player.canChangeContinent} onChange={(e) => {
                        setUser({
                            ...user,
                            player: {
                                ...user.player, 
                                canChangeContinent: e.target.checked
                            }
                        })
                    }} />
                    <Label>Change Continent</Label>
                </FormGroup>
            </Col>
            <Col xs="6">
                <FormGroup>
                    <Input type="checkbox" defaultChecked={user.player.canChangeCountry} onChange={(e) => {
                        setUser({
                            ...user,
                            player: {
                                ...user.player, 
                                canChangeCountry: e.target.checked
                            }
                        })
                    }} />
                    <Label>Change Country</Label>
                </FormGroup>
            </Col>
        </Row>
        <FormGroup>
            <Label>Minimum Salary</Label>
            <Input type="number" defaultValue={user.player.minSalary || 0}
            onChange={(e) => {
                setUser({
                    ...user,
                    player: {
                        ...user.player,
                        minSalary: e.target.valueAsNumber
                    }
                })
            }} />
        </FormGroup>
        <FormGroup>
            <Label>Desired Salary</Label>
            <Input type="number" defaultValue={user.player.wantedSalary || 0}
            onChange={(e) => {
                setUser({
                    ...user,
                    player: {
                        ...user.player,
                        wantedSalary: e.target.valueAsNumber
                    }
                })
            }} />
        </FormGroup>
        <hr />
        <Button color="primary" size="sm" className="float-end" onClick={toggleAddingImageModal}>+</Button>
        <h4>Photos</h4>
        {
            user.player.images &&
            JSON.parse(user.player.images).map((im, index) => {
            return <Row key={index}>
                {
                index % 2 === 0 && <Col xs="6" style={{padding: '0'}}>
                    <img src={fileService.getUrl(im.name)} style={{width: '100%'}} alt={`image ${index}`}/>
                </Col>
                }
                <Col xs="6" style={{padding: '20px'}}>
                    {im.description}
                </Col>
                {
                index % 2 === 1 && <Col xs="6" style={{padding: '0'}}>
                    <img src={fileService.getUrl(im.name)} style={{width: '100%'}} alt={`image ${index}`}/>
                </Col>
                }
            </Row>
            })
        }
        <hr />
        <Button color="primary" size="sm" className="float-end" onClick={toggleAddingVideoModal}>+</Button>
        <h4>Videos</h4>
        {
            user.player.videos &&
            JSON.parse(user.player.videos).map((vi, index) => {
            return <Row key={index}>
                <Col xs="12">
                    <iframe width="100%" height="200px" src={vi.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/').replace('https://youtu.be/', 'https://www.youtube.com/embed/')} 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                </Col>
            </Row>
            })
        }
        <br />
        <Button color="success" size="lg" onClick={savePlayer} style={{width: '100%'}}>Save Changes</Button>
        <br /><br />
        <Modal isOpen={showPosition1Modal} toggle={togglePosition1Modal} centered className="modal-xl">
            <ModalHeader toggle={togglePosition1Modal}>First Position</ModalHeader>
            <ModalBody>
                <Row className="ffield">
                    <Col xs="2" className={`pos ${user.player.position1 === 'LB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'LB');
                    }}>
                        LB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'LWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'LWB');
                    }}>
                        LWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'LM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'LM');
                    }}>
                        LM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'LW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'LW');
                    }}>
                        LW
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'LF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'LF');
                    }}>
                        LF
                    </Col>


                    <Col xs="2">
                        <Row>
                            <Col xs="6" className={`pos ${user.player.position1 === 'GK' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(1, 'GK');
                            }}>
                                GK
                            </Col>
                            <Col xs="6" className={`pos ${user.player.position1 === 'CB' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(1, 'CB');
                            }}>
                                CB
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'CDM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'CDM');
                    }}>
                        CDM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'CM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'CM');
                    }}>
                        CM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'CAM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'CAM');
                    }}>
                        CAM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'CF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'CF');
                    }}>
                        CF
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'ST' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'ST');
                    }}>
                        ST
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'RF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'RF');
                    }}>
                        RF
                    </Col>

                    <Col xs="2" className={`pos ${user.player.position1 === 'RB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'RB');
                    }}>
                        RB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'RWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'RWB');
                    }}>
                        RWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'RM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'RM');
                    }}>
                        RM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position1 === 'RW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(1, 'RW');
                    }}>
                        RW
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={togglePosition1Modal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={showPosition2Modal} toggle={togglePosition2Modal} className="modal-xl">
            <ModalHeader toggle={togglePosition2Modal}>Second Position</ModalHeader>
            <ModalBody>
            <Row className="ffield">
                    <Col xs="2" className={`pos ${user.player.position2 === 'LB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'LB');
                    }}>
                        LB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'LWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'LWB');
                    }}>
                        LWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'LM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'LM');
                    }}>
                        LM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'LW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'LW');
                    }}>
                        LW
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'LF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'LF');
                    }}>
                        LF
                    </Col>


                    <Col xs="2">
                        <Row>
                            <Col xs="6" className={`pos ${user.player.position2 === 'GK' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(2, 'GK');
                            }}>
                                GK
                            </Col>
                            <Col xs="6" className={`pos ${user.player.position2 === 'CB' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(2, 'CB');
                            }}>
                                CB
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'CDM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'CDM');
                    }}>
                        CDM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'CM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'CM');
                    }}>
                        CM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'CAM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'CAM');
                    }}>
                        CAM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'CF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'CF');
                    }}>
                        CF
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'ST' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'ST');
                    }}>
                        ST
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'RF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'RF');
                    }}>
                        RF
                    </Col>

                    <Col xs="2" className={`pos ${user.player.position2 === 'RB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'RB');
                    }}>
                        RB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'RWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'RWB');
                    }}>
                        RWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'RM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'RM');
                    }}>
                        RM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position2 === 'RW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(2, 'RW');
                    }}>
                        RW
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={togglePosition2Modal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={showPosition3Modal} toggle={togglePosition3Modal} className="modal-xl">
            <ModalHeader toggle={togglePosition3Modal}>Third Position</ModalHeader>
            <ModalBody>
            <Row className="ffield">
                    <Col xs="2" className={`pos ${user.player.position3 === 'LB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'LB');
                    }}>
                        LB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'LWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'LWB');
                    }}>
                        LWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'LM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'LM');
                    }}>
                        LM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'LW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'LW');
                    }}>
                        LW
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'LF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'LF');
                    }}>
                        LF
                    </Col>


                    <Col xs="2">
                        <Row>
                            <Col xs="6" className={`pos ${user.player.position3 === 'GK' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(3, 'GK');
                            }}>
                                GK
                            </Col>
                            <Col xs="6" className={`pos ${user.player.position3 === 'CB' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(3, 'CB');
                            }}>
                                CB
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'CDM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'CDM');
                    }}>
                        CDM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'CM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'CM');
                    }}>
                        CM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'CAM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'CAM');
                    }}>
                        CAM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'CF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'CF');
                    }}>
                        CF
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'ST' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'ST');
                    }}>
                        ST
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'RF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'RF');
                    }}>
                        RF
                    </Col>

                    <Col xs="2" className={`pos ${user.player.position3 === 'RB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'RB');
                    }}>
                        RB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'RWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'RWB');
                    }}>
                        RWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'RM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'RM');
                    }}>
                        RM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position3 === 'RW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(3, 'RW');
                    }}>
                        RW
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={togglePosition3Modal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={showPosition4Modal} toggle={togglePosition4Modal} className="modal-xl">
            <ModalHeader toggle={togglePosition4Modal}>Video call</ModalHeader>
            <ModalBody>
            <Row className="ffield">
                    <Col xs="2" className={`pos ${user.player.position4 === 'LB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'LB');
                    }}>
                        LB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'LWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'LWB');
                    }}>
                        LWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'LM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'LM');
                    }}>
                        LM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'LW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'LW');
                    }}>
                        LW
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'LF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'LF');
                    }}>
                        LF
                    </Col>


                    <Col xs="2">
                        <Row>
                            <Col xs="6" className={`pos ${user.player.position4 === 'GK' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(4, 'GK');
                            }}>
                                GK
                            </Col>
                            <Col xs="6" className={`pos ${user.player.position4 === 'CB' ? 'bg-success' : ''}`} onClick={() => {
                                setPosition(4, 'CB');
                            }}>
                                CB
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'CDM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'CDM');
                    }}>
                        CDM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'CM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'CM');
                    }}>
                        CM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'CAM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'CAM');
                    }}>
                        CAM
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'CF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'CF');
                    }}>
                        CF
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'ST' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'ST');
                    }}>
                        ST
                    </Col>


                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'RF' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'RF');
                    }}>
                        RF
                    </Col>

                    <Col xs="2" className={`pos ${user.player.position4 === 'RB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'RB');
                    }}>
                        RB
                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'RWB' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'RWB');
                    }}>
                        RWB
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'RM' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'RM');
                    }}>
                        RM
                    </Col>
                    <Col xs="2">

                    </Col>
                    <Col xs="2" className={`pos ${user.player.position4 === 'RW' ? 'bg-success' : ''}`} onClick={() => {
                        setPosition(4, 'RW');
                    }}>
                        RW
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={togglePosition4Modal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={addingImageModal} toggle={toggleAddingImageModal} centered className="modal-xl">
            <ModalHeader toggle={toggleAddingImageModal}>Upload a Photo</ModalHeader>
            <ModalBody>
                <Button color="warning" size="sm" onClick={() => {
                    document.getElementById('uploadImageInput').click()
                }}>Upload Photo</Button>
                <img src={fileService.getUrl(newImage.name)} style={{width: '80%'}}/>
                <Input type="file" style={{display: 'none'}} id="uploadImageInput"
                onChange={e => uploadImage(e.target.files[0])} />
                <Input type="textarea" onChange={e => commentOnNewImage(e.target.value)} />
                <Button color="primary" size="sm" onClick={appendToImages}>Add to Images</Button>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleAddingImageModal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={addingVideoModal} toggle={toggleAddingVideoModal} centered className="modal-xl">
            <ModalHeader toggle={toggleAddingVideoModal}>Add Video</ModalHeader>
            <ModalBody>
                
                <Label style={{color: '#003366'}}>Youtube URL</Label>
                <p style={{color: '#003366'}}>Upload your video on Youtube and then paste the link to the video here</p>
                <Input type="text" onChange={(e) => setNewYoutubeLink(e.target.value)} />
                <Button color="primary" size="sm" onClick={appendVideo}>Add Video</Button>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleAddingVideoModal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={successMessage} toggle={() => setSuccessMessage('')} centered className="modal-xl">
            <ModalBody className="bg-success text-white">
                {successMessage}
            </ModalBody>
            <ModalFooter className="bg-success">
                <Button color="success" onClick={() => setSuccessMessage('')}>OK</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={errorMessage} toggle={() => setErrorMessage('')} centered className="modal-xl">
            <ModalBody className="bg-danger text-white">
                {errorMessage}
            </ModalBody>
            <ModalFooter className="bg-danger">
                <Button color="danger" onClick={() => setSuccessMessage('')}>OK</Button>
            </ModalFooter>
        </Modal>
    </>
};

export default ProfileEditor;